import React from 'react'
import styled from '@emotion/styled'

import { breakpoints, colors, spacing } from '../../utils/styles'

const FooterRoot = styled('footer')`
  align-items: center;
  color: ${colors.textMild};
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  padding: ${spacing.md}px;
  padding-bottom: calc(${spacing.xl}px + 50px);

  a {
    color: ${colors.brand};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 50px;
    padding: 0 ${spacing.xl}px 10px;
  }
`

const Row = styled('span')`
  display: inline-block;
  flex-shrink: 0;
  line-height: 1.3;
  padding-bottom: ${spacing['2xs']}px;
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    line-height: 1.4;
    padding-bottom: 0;
  }
`

const Spacer = styled('span')`
  display: none;

  @media (min-width: ${breakpoints.desktop}px) {
    display: inline-block;
    padding: 0 ${spacing.sm}px;
  }
`

const Footer = () => (
  <FooterRoot>
    <Row>
      <b>Got questions?&nbsp;</b>
    </Row>
    <Row>
      Take a look at our <a href='/faqs'>FAQs</a>, talk to us on{' '}
      <a href='viber://chat?number=%2B639451135092'>Viber</a>{' '}/{' '}
      <a href='https://m.me/uvsoldier'>Facebook</a>
    </Row>
    <Row>
      &nbsp;or send an email to{' '}
      <a href='mailto:cs@uvsoldier.com'>cs@uvsoldier.com</a>
    </Row>
    <Spacer>•</Spacer>
    <Row>Copyright © 2005-2021 UV-Soldier</Row>
  </FooterRoot>
)

export default Footer
