import React from 'react'

// FIXME: remove shopify and add a dummy client
const client = {
  checkout: {
    create: () => {
      return { id: 0, lineItems: [] }
    },
    removeLineItems: (checkoutId, lineItemsToUpdate) => {
      return Promise.resolve({ id: 0, lineItems: [] })
    },
    addLineItems: (checkoutId, lineItemsToUpdate) => {
      const items = lineItemsToUpdate.map(x => {
        return {
          ...x,
          id: 'id',
          variant: {
            image: {
              id: 0
            }
          }
        }
      })
      return Promise.resolve({ id: 0, lineItems: items })
    }
  }
}

export const defaultStoreContext = {
  client,
  isCartOpen: false,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {}
}

const StoreContext = React.createContext(defaultStoreContext)

export const withStoreContext = Component => {
  return props => (
    <StoreContext.Consumer>
      {context => <Component {...props} storeContext={context} />}
    </StoreContext.Consumer>
  )
}

export default StoreContext
