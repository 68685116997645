import React from 'react';
import { colors } from '../utils/styles';

export default () => (
  <svg width="34" height="48" viewBox="0 0 34 48" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14C0 6.26844 5.92444 0 13.232 0C20.5404 0 26.464 6.26844 26.464 14C26.464 21.7316 20.5404 28 13.232 28C5.92444 28 0 21.7316 0 14Z"
      transform="translate(5.74127 12.2667)"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14C0 6.26844 5.92444 0 13.232 0C20.5404 0 26.464 6.26844 26.464 14C26.464 21.7316 20.5404 28 13.232 28C5.92444 28 0 21.7316 0 14Z"
      transform="translate(5.74127 12.2667)"
      stroke={colors.brand}
      strokeWidth="1.701"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.72 25.4676C10.72 25.4676 8.44444 24.0738 8.44444 19.6293C8.44444 17 10.2693 14.5929 12.5884 14.416C14.9076 14.24 17.7227 15.0373 19.704 15.0373C24.8151 15.0373 28.408 11.5556 28.408 7.55556C28.408 3.92622 24.6667 0 18.3707 0C16.3707 0 14.1858 1 14.1858 1C14.1858 1 16.6578 1.69422 16.6578 3.24978C16.6578 6 12.0924 7.08533 9.55556 7.77778C5.07467 9 0 11.9813 0 19.4818C0 27.9813 6.37067 31.5893 6.37067 31.5893"
      transform="translate(1 1.41283)"
      fill={colors.brandBright}
    />
    <path
      d="M10.72 25.4676C10.72 25.4676 8.44444 24.0738 8.44444 19.6293C8.44444 17 10.2693 14.5929 12.5884 14.416C14.9076 14.24 17.7227 15.0373 19.704 15.0373C24.8151 15.0373 28.408 11.5556 28.408 7.55556C28.408 3.92622 24.6667 0 18.3707 0C16.3707 0 14.1858 1 14.1858 1C14.1858 1 16.6578 1.69422 16.6578 3.24978C16.6578 6 12.0924 7.08533 9.55556 7.77778C5.07467 9 0 11.9813 0 19.4818C0 27.9813 6.37067 31.5893 6.37067 31.5893"
      transform="translate(1 1.41283)"
      stroke={colors.brand}
      strokeWidth="1.701"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.79 1.26646C12.4819 1.02529 12.4276 0.579994 12.6688 0.271857C12.91 -0.0362805 13.3553 -0.0905692 13.6634 0.150599C13.96 0.38271 14.3791 0.790643 14.7967 1.35626C15.5046 2.31506 15.9352 3.39077 15.9352 4.56098C15.9352 8.10019 13.4139 10.9026 8.94772 11.4857C5.40236 11.9483 1.417 13.4858 1.417 15.0792C1.417 15.4705 1.09979 15.7877 0.7085 15.7877C0.317206 15.7877 0 15.4705 0 15.0792C0 12.3445 4.38802 10.6517 8.76433 10.0807C12.532 9.58875 14.5182 7.38104 14.5182 4.56098C14.5182 3.72996 14.1989 2.93217 13.6567 2.19791C13.4622 1.93446 13.2532 1.69911 13.0451 1.49658C12.9236 1.3784 12.8334 1.30037 12.79 1.26646ZM16.5169 22.4818C16.7596 22.7888 16.7075 23.2344 16.4005 23.477C16.0936 23.7197 15.648 23.6676 15.4053 23.3606C15.0882 22.9594 14.6179 22.7231 14.1078 22.7231C13.193 22.7231 12.4536 23.4559 12.4536 24.3559C12.4536 24.5829 12.5035 24.8046 12.6003 25.0183C12.7617 25.3747 12.6036 25.7945 12.2472 25.956C11.8907 26.1174 11.4709 25.9593 11.3095 25.6029C11.1312 25.2092 11.0366 24.7889 11.0366 24.3559C11.0366 22.6705 12.4132 21.3061 14.1078 21.3061C15.0592 21.3061 15.9356 21.7466 16.5169 22.4818ZM22.486 19.4022C22.7808 19.6595 22.8112 20.1071 22.5539 20.4019C22.2966 20.6967 21.8491 20.7271 21.5543 20.4698C21.3785 20.3164 21.1724 20.207 20.9433 20.1489C20.1267 19.9412 19.2964 20.4341 19.0888 21.2501C19.0361 21.4563 19.0297 21.6687 19.0675 21.8856C19.1346 22.2711 18.8765 22.638 18.491 22.7051C18.1055 22.7722 17.7386 22.5141 17.6715 22.1286C17.6001 21.7184 17.6125 21.3039 17.7157 20.8999C18.1162 19.3261 19.7178 18.3752 21.2922 18.7755C21.739 18.8889 22.1443 19.104 22.486 19.4022Z"
      transform="translate(6.66254 1)"
      fill={colors.brand}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64089 3.82044C7.64089 5.93067 5.93067 7.64089 3.82045 7.64089C1.71022 7.64089 0 5.93067 0 3.82044C0 1.71111 1.71022 0 3.82045 0C5.93067 0 7.64089 1.71111 7.64089 3.82044Z"
      transform="translate(5.297 25.8568)"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.73644C0 6.73644 1.74044 3.66888 3.51822 2.84755C4.48178 2.4031 4.81511 3.36577 4.81511 3.36577L4.96267 4.55155L4.14844 5.29199L0.888889 7.51421L0 6.73644ZM10.3945 2.84319C10.3945 2.84319 12.15 0.527635 13.6923 0.0503021C14.5269 -0.208365 14.694 0.614747 14.694 0.614747L14.686 1.59963L13.9403 2.11608L11.0354 3.57475L10.3945 2.84319Z"
      transform="translate(15.1484 15.571)"
      fill={colors.brand}
    />
    <path
      d="M1.96267 0C1.96267 2.77778 6.18489 2.22222 6.18489 5.48178C6.18489 7.81511 3.80089 8.55556 2.88889 8.55556C1.976 8.55556 0 8.25956 0 7"
      transform="translate(23.1119 22.7459)"
      stroke={colors.brand}
      strokeWidth="1.417"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.57956 7.21244C5.05333 7.48622 4.45511 7.64 3.82044 7.64C1.71022 7.64 0 5.93067 0 3.82044C0 1.71111 1.71022 0 3.82044 0C4.82578 0 5.74133 0.388444 6.42311 1.02311"
      transform="translate(5.2962 25.8573)"
      fill="#fff"
    />
    <path
      d="M5.57956 7.21244C5.05333 7.48622 4.45511 7.64 3.82044 7.64C1.71022 7.64 0 5.93067 0 3.82044C0 1.71111 1.71022 0 3.82044 0C4.82578 0 5.74133 0.388444 6.42311 1.02311"
      transform="translate(5.2962 25.8573)"
      stroke={colors.brand}
      strokeWidth="1.417"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.7085 12.0837C0.317206 12.0837 0 11.7665 0 11.3752C0 10.9839 0.317206 10.6667 0.7085 10.6667C3.18304 10.6667 4.70135 9.7984 5.54527 8.30428C6.10916 7.30595 6.296 6.23165 6.296 5.15295C6.296 3.7934 5.68256 2.30473 4.63707 1.19414C4.36886 0.909229 4.3824 0.460836 4.66731 0.192626C4.95222 -0.0755845 5.40061 -0.0620462 5.66882 0.222864C6.95732 1.59159 7.713 3.42544 7.713 5.15295C7.713 6.45414 7.48845 7.74522 6.77906 9.00116C5.68143 10.9445 3.68936 12.0837 0.7085 12.0837Z"
      transform="translate(19.1807 1.74136)"
      fill={colors.brand}
    />
    <path
      d="M2.22222 1.33333L3.55556 0C3.55556 0 1.77778 4.17233e-07 0 0.888889"
      transform="translate(7.96344 28.9681)"
      stroke={colors.brand}
      strokeWidth="1.417"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.72133C0 7.72133 0.869333 4.51778 7.62844 3.85111L5.16889 5.39956L2.30311 8.46444L0 7.72133ZM18.6962 0C18.6962 0 17.7948 5.07378 10.6837 5.07378L12.4722 3.352L15.3877 2.24089L18.6962 0Z"
      transform="translate(6.50171 8.8943)"
      fill={colors.brandBright}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77639 0.313056C7.77639 0.313056 6.8875 -0.0202774 5.60928 0.7015C4.33195 1.42417 1.24039 4.43483 0.38706 4.38683C0.38706 4.38683 -0.445831 5.92372 0.331947 5.7575C1.10972 5.59039 8.49817 2.70239 8.60928 2.31306C8.72039 1.92372 7.77639 0.313056 7.77639 0.313056ZM11.637 0.00398876C10.2646 0.0946554 9.88235 1.02532 9.88235 1.02532C10.0859 1.77643 10.5055 2.53288 10.9597 2.37199C12.1846 1.94088 13.0912 3.48221 13.5881 3.58532C13.8966 3.75777 13.853 -0.141789 11.637 0.00398876Z"
      transform="translate(16.4648 30.2106)"
      fill={colors.brand}
    />
    <path
      d="M0 0L4.07467 0.685333"
      transform="translate(17.1166 2.54364)"
      stroke={colors.brand}
      strokeWidth="1.701"
    />
    <path
      d="M0 0L5.28444 0.709333"
      transform="translate(17.0407 4.08614)"
      stroke={colors.brand}
      strokeWidth=".992"
    />
    <path
      d="M0 0L4.888 0.811555"
      transform="translate(17.0364 5.57484)"
      stroke={colors.brand}
      strokeWidth=".85"
    />
    <path
      d="M0 0L5.30489 1.09333"
      transform="translate(16.321 6.95334)"
      stroke={colors.brand}
      strokeWidth=".709"
    />
    <path
      d="M0 0L6.78133 1.61867"
      transform="translate(13.7946 7.7832)"
      stroke={colors.brand}
      strokeWidth=".283"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63793 4.09617L0.742822 0.0321651C0.742822 0.0321651 -0.102511 -0.185613 0.0103778 0.512165C0.122378 1.20994 1.22816 9.41794 1.22816 9.41794L8.19793 5.68905L8.90904 5.4855L14.5659 7.70594L13.909 0.54061L9.54104 3.08728C9.54104 3.08728 9.2246 4.35128 8.63793 3.97617"
      transform="translate(12.5669 36.7143)"
      fill="#fff"
    />
    <path
      d="M8.63793 4.09617L0.742822 0.0321651C0.742822 0.0321651 -0.102511 -0.185613 0.0103778 0.512165C0.122378 1.20994 1.22816 9.41794 1.22816 9.41794L8.19793 5.68905L8.90904 5.4855L14.5659 7.70594L13.909 0.54061L9.54104 3.08728C9.54104 3.08728 9.2246 4.35128 8.63793 3.97617"
      transform="translate(12.5669 36.7143)"
      stroke={colors.brand}
      strokeWidth="1.417"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.12 0.802667C3.27822 1.05867 3.36889 1.36089 3.36889 1.68444C3.36889 2.61511 2.61511 3.36978 1.68444 3.36978C0.753778 3.36978 0 2.61511 0 1.68444C0 0.753778 0.753778 0 1.68444 0C2.29156 0 2.824 0.320889 3.12 0.802667Z"
      transform="translate(19.5074 39.8033)"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.12 0.802667C3.27822 1.05867 3.36889 1.36089 3.36889 1.68444C3.36889 2.61511 2.61511 3.36978 1.68444 3.36978C0.753778 3.36978 0 2.61511 0 1.68444C0 0.753778 0.753778 0 1.68444 0C2.29156 0 2.824 0.320889 3.12 0.802667Z"
      transform="translate(19.5074 39.8033)"
      stroke={colors.brand}
      strokeWidth="1.417"
    />
  </svg>
);
